<template>
    <div>
        <div class="s-top-bar relative font-alt" :class="{ 'mb-20 hidden lg:block': variant === 'main' }">
            <div class="s-container flex flex-row justify-between items-center bg-black">
                <slot name="left">
                    <div>
                        <PageHeaderPickupDeliveryControls @open="openModal" @close="$emit('close')" />
                    </div>
                </slot>

                <div class="mr-5 flex items-center float-right h-full justify-center">
                    <a class="text-white s-top-bar-link text-sm block" href="tel:3059122929">(305) 912-2929</a>

                    <a v-if="!xIsAuthenticated" class="text-white mx-2 s-top-bar-link-svg cursor-pointer block"
                        @click="xOpenLoginPopup">
                        <img src="@/assets/icon/svg/account.svg?url" alt="View your account" />
                    </a>
                    <a v-if="!xIsAuthenticated" class="text-white uppercase s-top-bar-link text-sm block"
                        @click="xOpenLoginPopup">Login</a>
                    <span v-if="!xIsAuthenticated" class="text-white mx-2">|</span>
                    <a v-if="!xIsAuthenticated" class="text-white uppercase mr-2 s-top-bar-link text-sm"
                        href="/registration">Join</a>
                    <!-- END IF NOT LOGGED IN -->

                    <!-- IF LOGGED IN -->
                    <a v-if="xIsAuthenticated"
                        class="text-white ml-4 s-top-bar-link-svg cursor-pointer pr-3 hide-app-mobile"
                        href="/profile/dashboard">
                        <img src="@/assets/icon/svg/account.svg?url" alt="View your account" />
                    </a>

                    <div v-if="xIsAuthenticated" class="member-dropdown h-full flex items-center justify-center z-[102]"
                        @mouseover="updateAllotment">
                        <a class="hidden md:inline-flex text-white s-top-bar-link text-sm px-3 text-left flex-col mr-4">
                            <small class="uppercase text-2xs leading-none">Welcome Back</small>
                            <div class="flex items-center justify-between w-full">
                                <span class="text-sm underline">{{ shortname }}</span>
                                <IconChevron class="s-top-bar-chevron text-white h-1.5" />
                            </div>
                        </a>
                        <div class="profile-dropdown-menu hidden h-auto group w-full top-14 right-0 fixed">
                            <div class="grid grid-cols-5 gap-2 items-center align-middle justify-center">
                                <div class="col-span-3 py-3 border-r border-bg-border allotment">
                                    <RenderlessXUser v-slot="{ user }">
                                        <div v-if="user.approved">
                                            <div id="header-allotment-ctrls" class="flex justify-around items-center">
                                                <button class="w-10 lg:w-12 flex justify-center items-center"
                                                    @click="slidePrev">
                                                    <span class="py-3">
                                                        <IconCaretLeft class="h-5 w-5 text-white" />
                                                    </span>
                                                </button>
                                                <h2 class="text-2xl text-pink">My Allotment</h2>
                                                <button class="w-10 lg:w-12 flex justify-center items-center"
                                                    @click="slideNext">
                                                    <span class="py-3">
                                                        <IconCaretRight class="h-5 w-5 text-white" />
                                                    </span>
                                                </button>
                                            </div>
                                            <Swiper ref="swiper" class="mt-4" v-bind="carouselSettings">
                                                <SwiperSlide>
                                                    <div class="flex flex-col gap-2 justify-center items-center">
                                                        <span class="text-teal mb-5">{{ user.routes.smoking.aggregate
                                                            }}</span>
                                                        <div class="h-8 flex items-end">
                                                            <img width="35" src="/allotment/Smokable.svg?url" />
                                                        </div>
                                                        <span
                                                            class="text-white uppercase tracking-widest font-semibold title">Smoking</span>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div class="flex flex-col gap-2 justify-center items-center">
                                                        <span class="text-teal mb-5">{{ user.routes.topicals }}</span>
                                                        <div class="h-8 flex items-end">
                                                            <img width="35" src="/allotment/Topicals.svg?url" />
                                                        </div>
                                                        <span
                                                            class="text-white uppercase tracking-widest font-semibold title">Topicals</span>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div class="flex flex-col gap-2 justify-center items-center">
                                                        <span class="text-teal mb-5">{{ user.routes.sublingual }}</span>
                                                        <div class="h-8 flex items-end">
                                                            <img width="35" src="/allotment/Sublingual.svg?url" />
                                                        </div>
                                                        <span
                                                            class="text-white uppercase tracking-widest font-semibold title">Sublingual</span>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div class="flex flex-col gap-2 justify-center items-center">
                                                        <span class="text-teal mb-5">{{ user.routes.edibles }}</span>
                                                        <div class="h-8 flex items-end">
                                                            <img width="35" src="/allotment/Edibles.svg?url" />
                                                        </div>
                                                        <span
                                                            class="text-white uppercase tracking-widest font-semibold title">Edibles</span>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div class="flex flex-col gap-2 justify-center items-center">
                                                        <span class="text-teal mb-5">{{ user.routes.inhalation }}</span>
                                                        <div class="h-8 flex items-end">
                                                            <img width="35" src="/allotment/Inhalation.svg?url" />
                                                        </div>
                                                        <span
                                                            class="text-white uppercase tracking-widest font-semibold title">Inhalation</span>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div class="flex flex-col gap-2 justify-center items-center">
                                                        <span class="text-teal mb-5">{{ user.routes.oral }}</span>
                                                        <div class="h-8 flex items-end">
                                                            <img width="35" src="/allotment/Oral.svg?url" />
                                                        </div>
                                                        <span
                                                            class="text-white uppercase tracking-widest font-semibold title">Oral</span>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div class="flex flex-col gap-2 justify-center items-center">
                                                        <span class="text-teal mb-5">{{ user.routes.suppository
                                                            }}</span>
                                                        <div class="h-8 flex items-end">
                                                            <img width="35" src="/allotment/Suppository.svg?url" />
                                                        </div>
                                                        <span
                                                            class="text-white uppercase tracking-widest font-semibold title">Suppository</span>
                                                    </div>
                                                </SwiperSlide>
                                            </Swiper>
                                        </div>

                                        <div v-else
                                            class="flex flex-col flex-grow text-center justify-center items-center h-full">
                                            <h2 class="text-2xl text-pink">View Your Rec After Your First Purchase!</h2>
                                        </div>
                                    </RenderlessXUser>
                                </div>
                                <div class="col-span-2">
                                    <div class="flex flex-row flex-grow w-full justify-center p-7 h-full items-center">
                                        <div class="flex flex-col w-1/2 text-left">
                                            <NuxtLink class="text-blue py-2" :to="'/profile/dashboard'">Dashboard
                                            </NuxtLink>
                                            <NuxtLink class="text-blue py-2" :to="'/profile/account-details'">Account
                                            </NuxtLink>
                                            <NuxtLink class="text-blue py-2" :to="'/profile/orders'">Orders</NuxtLink>
                                            <NuxtLink class="text-blue py-2" :to="'/profile/loyalty'">Loyalty</NuxtLink>
                                        </div>
                                        <div class="flex flex-col w-1/2 text-left">
                                            <NuxtLink class="text-blue py-2" :to="'/profile/medical'">Medical</NuxtLink>
                                            <NuxtLink class="text-blue py-2" :to="'/profile/payments'">Payments
                                            </NuxtLink>
                                            <NuxtLink class="text-blue py-2" :to="'/profile/address'">Address</NuxtLink>
                                            <a class="text-blue py-2" href="#"
                                                @click.prevent.self="logoutCurrentUser">Logout</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END IF LOGGED IN -->

                    <RenderlessXCart v-slot="{ xShoppingCart }">
                        <NuxtLink :to="'/cart'" class="text-white s-top-bar-link-svg hide-app-mobile">
                            <img src="@/assets/icon/svg/cart.svg?url" alt="View your shopping cart" />
                            <div class="s-badge s-badge-secondary text-sm rounded-full p-0.5">
                                {{ xShoppingCart.totalQuantity }}
                            </div>
                        </NuxtLink>
                    </RenderlessXCart>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import _phone from '@/utils/filters/phone'
import _shortName from '@/utils/filters/short-name'
import _capitalize from '@/utils/filters/capitalize'
import { $api } from '@/utils/api'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from 'swiper'
import 'swiper/css'

export default {
    name: 'TopNavbar',
    components: {
        Swiper,
        SwiperSlide
    },

    props: {
        variant: {
            type: String,
            required: false,
            default: 'main'
        }
    },

    data() {
        return {
            count: 0,
            hasShownLoginBar: false,
            messageBar: null,
            secondsToShowLoginBar: 3,
            carouselSettings: {
                modules: [Navigation],
                slidesPerView: 2,
                loop: true,
                centeredSlides: true
            }
        }
    },

    computed: {
        ...mapGetters('authuser', ['xCurrentUser', 'xIsAuthenticated']),
        ...mapGetters('auth', ['xNewRegistration']),

        phone() {
            return _phone(this?.xCurrentUser?.phone || null)
        },

        shortname() {
            return _shortName(this?.xCurrentUser)
        },

        capitalizedName() {
            return _capitalize(this?.xCurrentUser?.first_name || '')
        },

        addressList() {
            return this?.xCurrentUser?.addresses || null
        }
    },

    mounted() {
        this.getCartCount()
    },

    methods: {
        ...mapActions('auth', ['xLogout', 'xOpenLoginPopup']),
        ...mapActions('cart', ['xGetCartCount']),

        async getCartCount() {
            this.count = await this.xGetCartCount()
        },

        // @todo:
        updateAllotment() {
            if (this?.$refs?.carousels) {
                this.$refs.carousels?.update()
            }
        },

        async logoutCurrentUser() {
            await this.xLogout()
            const url = window.location.href
            window.location.href = url
        },

        openModal(modal) {
            this.$emit('open', modal)
        },
        slidePrev() {
            this.$refs.swiper.$el.swiper.slidePrev()
        },
        slideNext() {
            this.$refs.swiper.$el.swiper.slideNext()
        }
    }
}
</script>

<style lang="postcss" scoped>
.allotment {
    background-image: linear-gradient(#000 0%, #002d52 100%);
    @apply h-full;
}

.s-container {
    height: 100%;

    .s-top-bar-address-dropdown {
        text-align: left;

        >span {
            color: white !important;

            >strong {
                display: inline-block;
            }
        }
    }
}

.s-top-bar {
    @apply h-14;
}

.s-top-bar-link-svg {
    position: relative;
    display: inline-block;

    img {
        height: 35px;
    }
}

.s-top-bar-link {
    cursor: pointer;
}

.s-top-bar-link:hover {
    text-decoration: underline;
}

.s-badge {
    position: absolute;
    top: 0;
    right: -10px;
}

.s-badge-secondary {
    background: #f3b6d0ff;
    color: #002d52ff;
    min-width: 25px;
    text-align: center;
}

.member-dropdown {
    .profile-dropdown-menu {
        text-align: left;
        z-index: 12;
        background: #fff;
        box-shadow: 0 7px 8px 0 rgb(0 0 0 / 20%);
        width: 700px;

        .profile-title {
            @apply font-main;
            font-weight: 700;
        }
    }

    &:hover {
        .s-top-bar-chevron {
            transform: scaleY(-1);
        }

        .profile-dropdown-menu {
            display: block;
        }
    }
}

.s-dropdown-region-label {
    @apply font-alt;
}

.s-dropdown-region {
    @apply font-alt;
}
</style>

<style lang="postcss" scoped>
.allotment {
    background-image: linear-gradient(#000 0%, #002d52 100%);
    @apply h-full;
}

.s-container {
    height: 100%;

    .s-top-bar-address-dropdown {
        text-align: left;

        >span {
            color: white !important;

            >strong {
                display: inline-block;
            }
        }
    }
}

.s-top-bar {
    @apply h-14;
}

.s-top-bar-link-svg {
    position: relative;
    display: inline-block;

    img {
        height: 35px;
    }
}

.s-top-bar-link {
    cursor: pointer;
}

.s-top-bar-link:hover {
    text-decoration: underline;
}

.s-badge {
    position: absolute;
    top: 0;
    right: -10px;
}

.s-badge-secondary {
    background: #f3b6d0ff;
    color: #002d52ff;
    min-width: 25px;
    text-align: center;
}

.member-dropdown {
    .profile-dropdown-menu {
        text-align: left;
        z-index: 12;
        background: #fff;
        box-shadow: 0 7px 8px 0 rgb(0 0 0 / 20%);
        width: 700px;

        .profile-title {
            @apply font-main;
            font-weight: 700;
        }
    }

    &:hover {
        .s-top-bar-chevron {
            transform: scaleY(-1);
        }

        .profile-dropdown-menu {
            display: block;
        }
    }
}

.s-dropdown-region-label {
    @apply font-alt;
}

.s-dropdown-region {
    @apply font-alt;
}
</style>
